import React from 'react'
import CallCenterPage from './callcenter/CallCenterPage'
import Footer from './footer/Footer'

const CallCenter = () => {
    return (
        <div>
            <CallCenterPage />
            <Footer />
        </div>
    )
}

export default CallCenter
