import React, {useState, useEffect} from 'react'
import './GalleryImg.css'

import { Button } from 'react-bootstrap'

import img1 from './assets/img_.jpg'
import img2 from './assets/img_1.jpg'
import img4 from './assets/img_3.jpg'
import img5 from './assets/img_4.jpg'
import img6 from './assets/img_5.jpg'
import img7 from './assets/img_6.jpg'
import img8 from './assets/img_7.jpg'

const GalleryImg = () => {
    useEffect(() => window.scrollTo(0, 0), []);

    let data = [
        {
            id: 1,
            imgSrc: img1,
            alt: 'Opus Group Call Center, Telemarkeing'
        },
        {
            id: 2,
            imgSrc: img2,
            alt: 'Opus Group Call Center, Telemarkeing'
        },
        {
            id: 4,
            imgSrc: img4,
            alt: 'Opus Group Call Center, Telemarkeing'
        },
        {
            id: 5,
            imgSrc: img5,
            alt: 'Opus Group Call Center, Telemarkeing'
        },
        {
            id: 6,
            imgSrc: img6,
            alt: 'Opus Group Call Center, Telemarkeing'
        },
        {
            id: 7,
            imgSrc: img7,
            alt: 'Opus Group Call Center, Telemarkeing'
        },
        {
            id: 8,
            imgSrc: img8,
            alt: 'Opus Group Call Center, Telemarkeing'
        }
    ];

    const [model, setModel] = useState(false);
    const [tempimgSrc, setTempImgSrc] = useState('');
    const getImg = (imgSrc) => {
        setTempImgSrc(imgSrc);
        setModel(true);
    }

    return (
        <> 
        <div className="triangle-topleft" ></div>
        <div className="triangle-topleft2"></div>
            <div className={model ? 'model open' : 'model' }>
                <img src={tempimgSrc} alt="Opus Team Gallery" />
                <Button className="close__model" onClick={() => setModel(false)} > <i className="fas fa-times"></i> </Button>
            </div>
            <div className="gallery">
                {data.map((item, index) => {
                    return (
                        <div className="pics" hey={index} onClick={() => getImg(item.imgSrc)} >
                            <img key={item.id} src={item.imgSrc} alt={item.alt} style={{width: '100%'}} data-aos='flip-up' />
                        </div>
                    )
                })}
            </div>
        </>
    )

}

export default GalleryImg
