import React from 'react'

import ContactUs from './contact_us/ContactUs'
import Footer from './footer/Footer'

const Contact = () => {
       return (
              <div>
                  <ContactUs />  
                  <Footer /> 
              </div>
       )
}

export default Contact
