import React, { useState } from 'react';
import { BrowserRouter as Router, NavLink, Link, Route, Switch } from 'react-router-dom';
import { FiAlignRight, FiXCircle, FiChevronDown } from "react-icons/fi";
import logo from '../../img/logo.png';

// Import your components here
import Home from '../Home';
import ItServices from '../ItServices';
import CallCenter from '../CallCenter';
import BackOffice from '../BackOffice';
import Survey from '../Survey';
import Gallery from '../Gallery';
import Testimonials from '../Testimonials';
import BePartner from '../BePartner';
import Contact from '../Contact';

// This is the translator
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

const languages = [
    {
        code: 'en',
        name: 'English',
        country_code: 'US'
    },
    {
        code: 'it',
        name: 'Italian',
        country_code: 'IT'
    },
    {
        code: 'al',
        name: 'Albania',
        country_code: 'ALB'
    }
];

const Navbarmenu = () => {
    // Translator function
    const { t } = useTranslation();

    const [sticky, setSticky] = useState(false);
    const [isMenu, setisMenu] = useState(false);
    const [isResponsiveclose, setResponsiveclose] = useState(false);
    const toggleClass = () => {
        setisMenu(isMenu === false ? true : false);
        setResponsiveclose(isResponsiveclose === false ? true : false);
    };

    let boxClass = ["main-menu menu-right menuq1"];
    if (isMenu) {
        boxClass.push('menuq2');
    } else {
        boxClass.push('');
    }

    const [isMenuSubMenu, setMenuSubMenu] = useState(false);

    const toggleSubmenu = () => {
        setMenuSubMenu(isMenuSubMenu === false ? true : false);
    };

    let boxClassSubMenu = ["sub__menus"];
    if (isMenuSubMenu) {
        boxClassSubMenu.push('sub__menus__Active');
    } else {
        boxClassSubMenu.push('');
    }

    const chengeBackground = () => {
        if (window.scrollY >= 200) {
            setSticky(true);
        } else {
            setSticky(false);
        }
    };

    window.addEventListener('scroll', chengeBackground);

    return (
        <Router>
            <header className={sticky ? 'header__middle active' : 'header__middle'}>
                <div className="container-fluid header__middle__container">
                    <div className="row">
                        <div className="header__content">
                            <div className="header__middle__logo">
                                <NavLink exact activeClassName='is-active' to="/">
                                    <img src={logo} alt="logo" />
                                </NavLink>
                            </div>

                            <div className="header__middle__menus">
                                <nav className="main-nav ">
                                    {isResponsiveclose === true ? <>
                                        <span className="menubar__button" style={{ display: 'none' }} onClick={toggleClass}> <FiXCircle /> </span>
                                    </> : <>
                                        <span className="menubar__button" style={{ display: 'none' }} onClick={toggleClass}> <FiAlignRight /> </span>
                                    </>}

                                    <ul className={boxClass.join(' ')}>
                                        <li className="menu-item">
                                            <NavLink exact activeClassName='is-active' onClick={toggleClass} to="/"> {t('nav_home')} </NavLink>
                                        </li>
                                        <li onClick={toggleSubmenu} className="menu-item sub__menus__arrows">
                                            <Link to="#"> {t('nav_services')}  <FiChevronDown /> </Link>
                                            <ul className={boxClassSubMenu.join(' ')}>
                                                <li> <NavLink onClick={toggleClass} activeClassName='is-active' to="/itservices"> {t('nav_it_services')} </NavLink> </li>
                                                <li><NavLink onClick={toggleClass} activeClassName='is-active' to="/callcenter"> {t('nav_call_center')} </NavLink> </li>
                                                <li><NavLink onClick={toggleClass} activeClassName='is-active' to="/backoffice"> {t('nav_back_office')} </NavLink> </li>
                                                <li><NavLink onClick={toggleClass} activeClassName='is-active' to="/survey"> {t('nav_survey')} </NavLink> </li>
                                            </ul>
                                        </li>
                                        <li className="menu-item">
                                            <NavLink onClick={toggleClass} activeClassName='is-active' to="/Gallery"> {t('nav_gallery')} </NavLink>
                                        </li>
                                        <li className="menu-item">
                                            <NavLink onClick={toggleClass} activeClassName='is-active' to="/Testimonials"> {t('nav_testimonials')} </NavLink>
                                        </li>
                                        <li className="menu-item">
                                            <NavLink exact activeClassName='is-active' onClick={toggleClass} to="/bepartner"> {t('nav_partner')} </NavLink>
                                        </li>
                                        <li className="menu-item">
                                            <NavLink exact activeClassName='is-active' onClick={toggleClass} to="/Contact"> {t('nav_contact')} </NavLink>
                                        </li>
                                        <li className="menu-item">
                                            <a href="https://www.instagram.com/opus_cc/" target='_blank' rel='noreferrer'>
                                                <i className="fab fa-instagram social__icon"></i> Instagram
                                            </a>
                                        </li>
                                        <li className="menu-item">
                                            <div className="dropdown">
                                                <button className="btn btn-warning dropdown-toggle change__web__lang" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i className="fas fa-language"></i>
                                                </button>
                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    {languages.map(({ code, name, country_code }) => (
                                                        <li key={country_code}>
                                                            <button className="dropdown-item" onClick={() => i18next.changeLanguage(code)}>
                                                                {name}
                                                            </button>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <Switch>
                <Route path="/" exact component={Home} />
                <Route path="/itservices" component={ItServices} />
                <Route path="/callcenter" component={CallCenter} />
                <Route path="/backoffice" component={BackOffice} />
                <Route path="/survey" component={Survey} />
                <Route path="/Gallery" component={Gallery} />
                <Route path="/Testimonials" component={Testimonials} />
                <Route path="/bepartner" component={BePartner} />
                <Route path="/Contact" component={Contact} />
                {/* Add other routes here */}
            </Switch>
        </Router>
    );
}

export default Navbarmenu;
