import React from 'react'
import { Link } from 'react-router-dom';
import './AboutUs.css';
import aboutusimg from './assets/img/img_3.jpg'

//this is translantor
import { useTranslation } from 'react-i18next';

const AboutUs = () => {
    //translantor function
    const { t } = useTranslation()


    const img = aboutusimg;

    return (
        <div className="about__us__section">
            <div className="container">
                <div className="row">
                        <h3 data-aos='fade-up'> {t('nav_about')} </h3>
                    <div className="col-md-6 col-sm-12" data-aos='fade-up'>
                        <p> <b> {t('company_name')} </b> {t('about_us')} </p>
                    </div>
                    <div className="col-md-6 col-sm-12" data-aos='fade-up'>
                        <img className="aboutus__img" src={img} alt="About-us" />
                    </div>
                    <div className='red__more__about__us__section'>
                       <Link to='/About' className="btn btn-primary read__more__aboutus" data-aos='fade-up' > {t('read_more_btn')} </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutUs
