import React from 'react'
import './Content.css';
import { useEffect } from 'react';
//=== Slick slider import here ===
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import aboutimg1 from '../../../assets/Img/img_.jpg';
import aboutimg2 from '../../../assets/Img/img_1.jpg';
import aboutimg3 from '../../../assets/Img/img_7.jpg';

import { Container } from 'react-bootstrap';

//=== translantion ===
import { useTranslation } from 'react-i18next';


const Content = () => {

    useEffect(() => window.scrollTo(0, 0), []);
    //=== translantion function ===
    const { t } = useTranslation()

//=== Slisck slider settings ===
    var settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1
      };

    return (
        <div className="about__us__description">
            <div className="aboutus__decoration"></div>
                <div className="container">
                    <div className="aboutus__desc__block" data-aos='zoom-in'>
                        <div className="row">
                            <div className="col-12">
                                <h3> {t('nav_about')} </h3>
                                <p> <b> {t('company_name')} </b> {t('about_us_page')} </p>
                            </div>
                            <div className="col-12">
                                {/* === Add slick slider here === */}
                                <div className="aboutus___images wrapper" data-aos='zoom-out-down'>
                                    <Container>
                                        <Slider {...settings}>
                                            <div>
                                                <h3> <img src={aboutimg1} alt="Opus team " /> </h3>
                                            </div>
                                            <div>
                                                <h3> <img src={aboutimg2} alt="Opus team" /> </h3>
                                            </div>
                                            <div>
                                                <h3> <img src={aboutimg3} alt="Opus team" /> </h3>
                                            </div>
                                        </Slider>
                                    </Container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <div className="aboutus__decoration__2"></div>
        </div>
    )
}

export default Content
