import React from 'react'
import SurveyPage from './Survey/SurveyPage'
import Footer from './footer/Footer'

const CallCenter = () => {
    return (
        <div>
            <SurveyPage />
            <Footer />
        </div>
    )
}

export default CallCenter
