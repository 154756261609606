import React, {useState, useEffect} from 'react';
import ReactGA from 'react-ga';

//=== import navbar ===
import Navbarmenu from './components/menu/Navbarmenu';

import {BrowserRouter as Router,Switch,Route} from "react-router-dom";

//Impoer Cookie
import CookieConsent from "react-cookie-consent";

// translantor 
import { useTranslation } from 'react-i18next';
import './App.css';

//=== import loader ===
import RingLoader from "react-spinners/RingLoader";

import Home from './components/Home';
import About from './components/About';
import itservices from './components/ItServices';
import callcenter from './components/CallCenter';
import survey from './components/Survey';
import backoffice from './components/BackOffice';
import BePartner from './components/BePartner';
import Contact from './components/Contact';
import Gallery from './components/Gallery';
import Testimonials from './components/Testimonials'

//=== import on scroll animation ===
import AOS from 'aos';
import 'aos/dist/aos.css';

//=== import darkmode styled component ===
import styled, { ThemeProvider } from 'styled-components';
import { lightTheme, darkTheme, GlobalStyles } from './themes.js';

const StyledApp = styled.div`
  color: ${(props) => props.theme.fontColor};
`;

const TRACKING_ID = "UA-233402301-1"; // TRACKING ID
  ReactGA.initialize(TRACKING_ID);

function App() {

  //=== loader function ===
  const [ loading, setLoading ] = useState(false);

  //translantion function
  const { t } = useTranslation()

  useEffect(() => {
    setLoading(true)
  }, [])

  setTimeout(() => {
    setLoading(false)
  }, 1000)

  //=== set theme darg & light ===
  const [theme, setTheme] = useState("dark")

  const setMode = mode => {
    window.localStorage.setItem('theme', mode)
    setTheme(mode);
  }

  const themeToggler = () => {
    theme === "light" ? setMode("dark") : setMode("light");
  }

  useEffect(() => {
    const localTheme = window.localStorage.getItem('theme')
    localTheme ? setTheme(localTheme) : setMode('light');
  }, []);

  //=== animate website on scroll ===
  AOS.init()

  return (
    <div className="App">
      {
        loading ? 

        <RingLoader className="loader__website" color={"#E4C865"} loading={loading} size={150} />

        :

        <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
          <Router basename="/">

            <StyledApp>
                <button className="darkmode__lightmode__switch" onClick={() => themeToggler()} > <i className="far fa-moon"></i> </button>
            </StyledApp>
            {/* === Add Menu Component === */}
            <Navbarmenu />
            
            <Switch> 
              <Route exact path="/" component={Home}/>
              <Route path="/About" component={About}/>
              <Route path="/itservices" component={itservices}/>
              <Route path="/callcenter" component={callcenter}/>
              <Route path="/survey" component={survey}/>
              <Route path='/gallery' component={Gallery} />
              <Route path='/backoffice' component={backoffice} />
              <Route path='/testimonials' component={Testimonials} />
              <Route path="/bepartner" component={BePartner}/>
              <Route path="/Contact" component={Contact}/>

              
            </Switch>

            
          </Router>
      
        <GlobalStyles />
        <CookieConsent>{t('cookies')}</CookieConsent>

      </ThemeProvider>

      }

    </div>
  );
}
export default App;
