import React from 'react';
import './Footer.css';

//translantor
import { useTranslation } from 'react-i18next';

const Footer = () => {
    //translantion function
    const { t } = useTranslation()

    return (
        <div className="as__footer">
            <div className="container">
                <div className="footer__content">
                    <div className="row">
                        <div className="col">
                            <h3 className="footer_adress" > {t('footer_ad')} </h3>
                            <p className="footer_sub__adress"> {t('footer_ad_rr')} <br />
                            {t('footer_adr')} <br />
                            {t('footer_adr_rr_only')} <br />
                            {t('footer_open_d')} <br />
                            {t('footer_open_h')} </p>
                        </div>
                        <div className="col">
                            <h3 className="footer_contact"> {t('footer_contact')} </h3>
                            <a href="mailto:hr.opus@hotmail.com?subject=Hello Opus&body=This is a message from website" target="_blank" rel="noreferrer" > <p className="footer_sub__email" > {t('footer_email')} </p> </a>
                            <a href="tel:+355698729117 " target="_blank" rel="noreferrer" > <p className="footer_sub__email" > +355 69 872 9117 </p> </a>
                            <a href="https://www.facebook.com/OPUS.SHPK.CC" target='_blank' rel='noreferrer' > <li> <i className="fab fa-facebook social__icon"></i> </li> </a>
                                <li> <i className="fab fa-linkedin social__icon"></i> </li> 
                            <a href="https://www.instagram.com/opus_cc/" target='_blank' rel='noreferrer' > <li> <i className="fab fa-instagram social__icon"></i> </li> </a>
                        </div>
                        <div className="col">
                            <h3 className="footer_menu"> {t('footer_menu')} </h3>
                            <a href="/itservices"> <li className="footer_sub__menu">  {t('nav_services')}  </li> </a>
                            <a href="/gallery"> <li className="footer_sub__menu"> {t('nav_gallery')} </li> </a>
                            <a href="/contact"> <li className="footer_sub__menu"> {t('nav_contact')} </li> </a>
                            <a href=""> <li className="footer_sub__menu"> {t('footer_privacy_policy')} </li> </a>
                        </div>
                        <div className="col">
                            <div className="whatsapp__icon__footer">
                                <a href='https://wa.me/+355698729117/?text=Hello Opus!' target='_blank' rel='noreferrer' > <i className="fab fa-whatsapp"></i> </a>
                            </div>
                        </div>

                        <div className="col-12 col-md-12 col-sm-12">
                            <div className="copy__right">
                                <h6> {t('footer_copyright')} </h6>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer
