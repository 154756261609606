import React from 'react'
import BackOfficePage from './backoffice/BackOfficePage'
import Footer from './footer/Footer'

const BackOffice = () => {
    return (
        <div>
            <BackOfficePage />
            <Footer />
        </div>
    )
}

export default BackOffice
