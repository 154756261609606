import React from 'react'
import { useEffect } from 'react'
import './CallCenterPage.css'

import { Card } from 'react-bootstrap'
import { Row } from 'react-bootstrap'
import { Col } from 'react-bootstrap'

import img1 from './assets/help-desk.png'
import img2 from './assets/toll-number.png'
import img3 from './assets/teleselling.png'
import img4 from './assets/telemarketing.png'

//=== translantor ===
import { useTranslation } from 'react-i18next'

const CallCenterPage = () => {
    
    useEffect(() => window.scrollTo(0, 0), []);

    //=== translantion function ===
    const { t } = useTranslation()
    
    return (
        <div className="call__center__page">
             {/* === Add shape on background === */}
             <div className="moon"></div>
            <div className="moon2"></div>
            <div className="moon3"></div>
            <div className="moon4"></div>
            <div className="moon5"></div>
            <div className="moon6"></div>
            <div className="moon7"></div>
            <div className="moon8"></div>
            <div className="moon9"></div>
            <div className="moon10"></div>
            {/* === shape on background end here === */}
             <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="call__center__card" data-aos='fade-up'>
                            <Card className='card' >
                                <Row className='no-gutters'>
                                    <Col md={4} lg={4}  >
                                        <Card.Img className='card__img' variant="top" src={img3} />
                                    </Col>
                                    <Col>
                                        <Card.Body>
                                            <Card.Title className='card__tittle' > {t('Inbound_Outbound_title')} </Card.Title>
                                            <Card.Text className='card__description'>
                                            {t('Inbound_Outbound_card')}
                                            </Card.Text>
                                            <a href="mailto:hr.opus@hotmail.com?subject=🆘 Commercial Teleselling&body=Hello Opus! I need help with Commercial Teleselling" target='_blank' rel='noreferrer' className='btn btn-yellow order__btn col-md-4 text-center'> {t('order_card_btn')} <i className="fas fa-envelope"></i>
                                                <div className="button__horizontal"></div>
                                                <div className="button__vertical"></div>
                                            </a> 
                                        </Card.Body>
                                    </Col>
                                </Row>
                            </Card>
                        </div>
                        <div className="call__center__card" data-aos='fade-up'>
                            <Card className='card' >
                                <Row className='no-gutters'>
                                    <Col md={4} lg={4}  >
                                        <Card.Img className='card__img' variant="top" src={img4} />
                                    </Col>
                                    <Col>
                                        <Card.Body>
                                            <Card.Title className='card__tittle' > {t('telemarketing')}  </Card.Title>
                                            <Card.Text className='card__description'>
                                            {t('teleselling_card')}
                                            </Card.Text>
                                            <a href="mailto:hr.opus@hotmail.com?subject=🆘 Telemarketing&body=Hello Opus! I need help with Telemarketing " target='_blank' rel='noreferrer' className='btn btn-yellow order__btn col-md-4 text-center'> {t('order_card_btn')} <i className="fas fa-envelope"></i>
                                                <div className="button__horizontal"></div>
                                                <div className="button__vertical"></div>
                                            </a> 
                                        </Card.Body>
                                    </Col>
                                </Row>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CallCenterPage
