import React, {useState} from 'react'
import './OpusTeam.css'

const Team = ({ name, descr, img, pro, }) => {
    
    const [show, setShow] = useState(false)

    return (
        <div className='testimonials__card'>
            <div className="container">
                <div className="card col-12 col-md-12 col-sm-12 col-xs-12">
                        <img src={img} alt="" />
                        <h4> {name} </h4>
                        <p> {pro} </p>
                        <button onClick={() => setShow(!show)} className="extend__btn"> <i className="fas fa-chevron-down"></i> </button>
                    <div className="container extend__user">
                        { show? <p> {descr} </p>:null }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Team
