import React from 'react'

//HELMET FOR SEO
import { Helmet } from 'react-helmet';

import HeaderCarousel from './carousel/HeaderCarousel';
import ServicesCard from './main-services/ServicesCard';
import AboutUs from './main-services/About-us/AboutUs';
import OurServices from './main-services/it-services/OurServices';
import WhyUs from './main-services/why-us/WhyUs';
import Footer from './footer/Footer';
 
const Home = () => {
	return (
                <>
                <Helmet>
                        <meta charSet="utf-8" />
                        <meta name="description" content="Opus Group is a powerful call center solution, empowering callers to make their work more efficient than ever. Enter for more!" />
                        <link rel="canonical" href="http://www.opus.al/" />
                </Helmet>
                        <HeaderCarousel />
                        <ServicesCard />
                        <AboutUs />
                        <OurServices />
                        <WhyUs />
                        <Footer />
                </>  
       )
}
export default Home
