import React from 'react'
import './OurServices.css';
import ItServicesImages from './assets/it-services.png';
import CallCenterImages from './assets/call_center.png';

// translantor 
import { useTranslation } from 'react-i18next';

const ItServices = () => {
    //translantion function
    const { t } = useTranslation()

    const ItServiceImg = ItServicesImages
    const CallCenterImg = CallCenterImages

    return (
        <div className="our__services">
                <div className="container">

            <div className="call__center__section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-sm-12" data-aos='fade-up'>
                            <img src={CallCenterImg} alt="call-center" />
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className="call__center__tittle" data-aos='fade-up'>
                                <h3> {t('nav_call_center')} </h3>
                            </div>
                            <div className="call__center__description" data-aos='fade-up'>
                                <li> <i className="fas fa-circle-notch"></i> {t('teleselling_out')} </li>
                                <li> <i className="fas fa-circle-notch"></i> {t('teleselling_in')} </li>
                                <li> <i className="fas fa-circle-notch"></i> {t('lead_generation')} </li>
                                <li> <i className="fas fa-circle-notch"></i> {t('survey_service')} </li>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>

            <div className="it__services__section">
                    <div className="row">
                        
                        <div className="col-md-6 col-sm-12">
                                <div className="it__services__tittle" data-aos='fade-up'>
                                    <h3> {t('nav_it_services')} </h3>
                                </div>
                                <div className="it__services__description" data-aos='fade-up'>
                                    <li> <i className="fas fa-circle-notch test"></i> {t('server_maintanance')}  </li>
                                    <li> <i className="fas fa-circle-notch"></i> {t('crm_consulting')} </li>
                                    <li> <i className="fas fa-circle-notch"></i> {t('network_connection')} </li>
                                    <li> <i className="fas fa-circle-notch"></i> {t('call_center_confg')} </li>
                                    <li> <i className="fas fa-circle-notch"></i> {t('hosting')} </li>
                                    <li> <i className="fas fa-circle-notch"></i> {t('help_desk')} </li>
                                </div>
                        </div>
                        <div className="col-md-6 col-sm-12" data-aos='fade-up'>
                            <img src={ItServiceImg} alt="It-services" />
                        </div>
                    </div>
                </div>
            </div>

            

        </div>

    )
}

export default ItServices
