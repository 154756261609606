import React from 'react';
import './ServicesCard.css';

import { Link } from 'react-router-dom';

//this is translantor
import { useTranslation } from 'react-i18next';

const ServicesCard = () => {
     //translantor function
     const { t } = useTranslation()

    return (
        <div className="services__cards">
            <div className="row">
                <div className="card col-12 col-md-3 col-sm-12 col-xs-12">
                    <Link to='/CallCenter' className='link__to__card'>
                        <div className="card__content">
                            <h1> 01 </h1>
                            <div className="card__dontent__description">
                                <h3> {t('callcenter_card_tittle')} </h3>
                                <p> {t('callcenter_card_description')} </p>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="card col-12 col-md-3 col-sm-12 col-xs-12">
                    <Link to='/BackOffice' className='link__to__card'>
                        <div className="card__content">
                            <h1> 02 </h1>
                            <div className="card__dontent__description">
                                <h3> {t('backoffice_card_tittle')} </h3>
                                <p> {t('backoffice_card_description')} </p>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="card col-12 col-md-3 col-sm-12 col-xs-12">
                    <Link to='/ItServices' className='link__to__card'>
                        <div className="card__content">
                            <h1> 03 </h1>
                            <div className="card__dontent__description">
                                <h3> {t('it_card_tittle')} </h3>
                                <p> {t('it_card_description')} </p>
                            </div>
                        </div>
                   </Link>
                </div>
                
            </div>
        </div>
        
    )
}

export default ServicesCard
