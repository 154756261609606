import React from 'react'
import emailjs from 'emailjs-com';
import { useEffect } from 'react';
//this is translantor
import { useTranslation } from 'react-i18next';

import { Typography, Card, CardContent, Grid, TextField, Button } from '@material-ui/core';

import './ContactUs.css';
 
const ContactUs = () => {
    
    useEffect(() => window.scrollTo(0, 0), []);
    //translante function
    const { t } = useTranslation()

    function sendEmail(e) {
        e.preventDefault();
    
        emailjs.sendForm('service_3opsbz7', 'template_u88uycv', e.target, 'jGBW_Cy-sGqgXo5bd')
          .then((result) => {
              alert('Thank you! We will contact you soon'); 
          }, (error) => {
              console.log(error.text);
          });
          e.target.reset()
      }

    return (
        <div className='contact__us__page'>
            {/* Add shape on background */}
            <div className="moon"></div>
            <div className="moon2"></div>
            <div className="moon3"></div>
            <div className="moon4"></div>
            <div className="moon5"></div>
            <div className="moon6"></div>
            <div className="moon7"></div>
            <div className="moon8"></div>
            <div className="moon9"></div>
            <div className="moon10"></div>
            {/* shape on background end here */}
            <div className="octagon"></div>
            <div className="octagon2"></div>
            <div className="container">
                <div className='container contact__form__top_content'>
                <div className="row">
                    <div className="col-12 contactus__tittle" data-aos='fade-down'>
                        <h3> {t('contact_page_tittle')} </h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 col-sm-12 d-flex justify-content-between" data-aos="fade-right">
                        <a href='tel:+355698729117' className='btn number__contact'> <i className="fas fa-phone-alt"></i> 355 69 872 9117 </a>
                        <a href='mailto:hr.opus@hotmail.com?subject=Hello Opus&body=This is message from opus.al' target='_blank' rel='noreferrer' className="btn email__contact"> <i className="fas fa-envelope"></i> hr.opus@hotmail.com </a>
                    </div>
                </div>
                </div>
                <div className="row">
                    <div className="col-12" data-aos="fade-up" >
                        {/* <h3 className='online__tittle' > Apply online for a work positon </h3> */}

                            <div className="contact__form container">
                                <Card>
                                    <CardContent>
                                     <Typography gutterBottom variant="h4" > {t('contact_page_card_tittle')} </Typography>
                                     <Typography gutterBottom variant="body" > {t('contact_page_card_body_txt')} </Typography>
                                        <form className="contact-form" onSubmit={sendEmail}>
                                            <Grid container spacing={2}>
                                                <Grid xs={12} item>
                                                    <TextField /*label={t('form_name')}*/ placeholder={t('form_plch_first_name')} variant='outlined' fullWidth required name="first_name" />
                                                </Grid>
                                                <Grid xs={12} item>
                                                    <TextField /*label={t('form_name')}*/ placeholder={t('form_plch_last_name')} variant='outlined' fullWidth required name="last_name" />
                                                </Grid>
                                                <Grid xs={12} item>
                                                    <TextField type="email" /*label={t('form_email')}*/ placeholder={t('form_plch_email')} variant='outlined' fullWidth required name="email" />
                                                </Grid>
                                                <Grid xs={12} item>
                                                    <TextField type="number" /*label={t('form_number')}*/ placeholder={t('form_plch_number')} variant='outlined' fullWidth required name="number" />
                                                </Grid>
                                                <Grid xs={12} item>
                                                    <TextField /*label={t('form_message')}*/ multiline rows={4} placeholder={t('form_plch_message')} variant='outlined' fullWidth required name="message" />
                                                </Grid>

                                                    <Grid xs={12} item>
                                                    <select id="cars" name="position">
                                                        <option value="selected"> {t('form_position_apply')} </option>
                                                        <option value="it"> {t('form_position_it')} </option>
                                                        <option value="developer"> {t('form_position_developer')} </option>
                                                        <option value="operator">  {t('form_position_operator')} </option>
                                                    </select>
                                                    <select id="cars" name="language">
                                                        <option value="language"> {t('form_language')} </option>
                                                        <option value="italian"> {t('form_italian')} </option>
                                                        <option value="english"> {t('form_english')} </option>
                                                        <option value="greek"> {t('form_greek')} </option>
                                                    </select>
                                                    </Grid>

                                                <Grid xs={12} item>
                                                    <Button type='submit' variant='contained' className="btn apply__button" fullWidth> {t('form_apply')} </Button>                                        
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </CardContent>
                                </Card>
                            </div>

                        {/* <div className="contact__form">

                            <form className="contact-form" onSubmit={sendEmail}>
                                <input type="name" className='name' placeholder='Name Surname' name="name" />
                                <input type="email" className='email' placeholder='E-mail' name="email" />
                                <input type="subject" className='subject' placeholder='Subject' name="subject" />

                                <select id="cars" name="position">
                                    <option value="selected"> Position you Apply </option>
                                    <option value="it"> IT </option>
                                    <option value="developer"> Developer</option>
                                    <option value="operator"> Operator</option>
                                </select>
                                <select id="cars" name="language">
                                    <option value="language"> Language </option>
                                    <option value="italian">Italian</option>
                                    <option value="english">English</option>
                                    <option value="greek">Greek</option>
                                </select>

                                <textarea type="message" rows='4' cols='50' className='message' placeholder='Message' name="message" />
                                <input type='submit' className="btn apply__button" value="Apply" ></input>
                            </form>

                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactUs
