import React, { useState, useEffect } from 'react'
import Team from './Team';
import './OpusTeam.css'

import user from './assets/user.png';
import mario_haslika from './assets/Mario_haslika.png';
import jasmina_shabani from './assets/Jasmina_shabani.png';
import gentjan_ndoja from './assets/Gentjan_ndoja.png';
import xhuljo_balliu from './assets/xhuljo_balliu.png';
import ledio_hysi from './assets/ledio_hysi.png';
import kantida_bendo from './assets/kantida_bendo.png';
import eriana_hasanaliaj from './assets/eriana_hasanaliaj.png';
import remir_llakaj from './assets/remir_llakaj.png';

const OpusTeam = () => {
    useEffect(() => window.scrollTo(0, 0), []);

const [show, setShow] = useState(false)
const [show2, setShow2] = useState(false)
const [show3, setShow3] = useState(false)

const [users, setUsers] = useState([
  
    {
        id: 2,
        img: kantida_bendo,
        user: 'Kantida Bendo',
        pro: 'CEO',
        descr: 'DESCRIPTION'
    },
    {
        id: 5,
        img: eriana_hasanaliaj,
        user: 'Eriana Hasanaiaj',
        pro: 'Human Resources',
        descr: 'I have been working for OPUS GROUP for over 2 years now. I love it here because the managers make sure that my strengths and my work tasks match. I have the freedom to find and implement my own role and everyone is ready to listen. We work really hard but we have fun doing it and we always keep the atmosphere so warm and so supportive.Each day I find new things that make OPUS a great place to work!'
    },
    {
        id: 3,
        img: mario_haslika,
        user: 'Mario Haslika',
        pro: 'Business Consultant',
        descr: 'I am part of Opus Group, from the 1st day that the company started its activity. During this time I have made important steps from a professional point of view. I am very satisfied and I feel appreciated, I can also say that meritocracy is one of the strong points respected by Opus.'
    },
    {
        id: 4,
        img: jasmina_shabani,
        user: 'Jasmina Shabani ',
        pro: 'Back Office',
        descr: 'I have been part of the Back Office department for 5 years, although I have had some disconnections, I have always returned to Opus.By working with more dedication and always having maximum support, I have managed to advance in my career.'
    },
    {
        id: 8,
        img: remir_llakaj,
        user: 'Remir Llakaj',
        pro: 'Financial Accountant',
        descr: 'Description'
    },
    // {
    //     id: 4,
    //     img: user,
    //     user: 'Eriana  Hasanaliaj',
    //     pro: 'Profession',
    //     descr: 'I believe, (and I think u agree with me )that employees are the most valuable asset, so I work passionately every day to create a healthy work atmosphere and provide them with the tools and support to do their jobs effectively. OPUS GROUP has given me the chance to consolidate my professional profile and “green light” to test my new HR ideas in practical situation. Come and join us if u want a people oriented company!'
    // },
    // {
    //     id: 5,
    //     img: user,
    //     user: 'Olgert Brisku',
    //     pro: 'IT Specialist & Developer',
    //     descr: 'I want to express my gratitude to the Opus company that gave me the opportunity to be part of it, my time here has been intense, challenging, fun and at the same time very rewarding. My job position is IT Specialist, but Opus has given me the opportunity to improve my skills as a Developer as well. I would recommend anyone to become part of Opus as it really is one of the best companies, trustworthy, highly skilled and experienced team members, this will be a great help for you as well to grow your career.'
    // },
    {
        id: 6,
        img: xhuljo_balliu,
        user: 'Xhuljo Balliu',
        pro: 'Sales Coordinator',
        descr: 'Description'
    },
    {
        id: 7,
        img: gentjan_ndoja,
        user: 'Gentjan Ndoja',
        pro: 'Back Office',
        descr: 'Description'
    },
    {
        id: 9,
        img: ledio_hysi,
        user: 'Ledjo Hysi',
        pro: 'Team Leader',
        descr: 'Description'
    },

]);

    return (
        <div className='opus__team'>
            <div className="container">
                {/* <div className="row ceo__row">
                    <div className="ceo__card card col-12 col-md-12 col-sm-12 col-xs-12">
                            <img src={user} alt="" />
                            <h3> Kandita Bendo </h3>
                            <p> CEO </p>
                            <button onClick={() => setShow(!show)} className="extend__btn"> <i className="fas fa-chevron-down"></i> </button>
                        <div className="container extend__user">
                            { show? <p> Description </p>:null }
                        </div>
                    </div>
                </div> */}
                {/* <div className="row immportant__row">
                    <div className="ceo__card card col-12 col-md-6 col-sm-12 col-xs-12">
                            <img src={user} alt="" />
                            <h3> User </h3>
                            <p> Profession </p>
                            <button onClick={() => setShow2(!show2)} className="extend__btn"> <i className="fas fa-chevron-down"></i> </button>
                        <div className="container extend__user">
                            { show2? <p> Description </p>:null }
                        </div>
                    </div>
                    <div className="ceo__card card col-12 col-md-6 col-sm-12 col-xs-12">
                            <img src={user} alt="" />
                            <h3> User </h3>
                            <p> Profession </p>
                            <button onClick={() => setShow3(!show3)} className="extend__btn"> <i className="fas fa-chevron-down"></i> </button>
                        <div className="container extend__user">
                            { show3? <p> This is description for this user. </p>:null }
                        </div>
                    </div>
                </div> */}
                <div className='container'>
                    <div className='leadership__title'>
                        <h3>Leadership</h3>
                    </div>
                </div>
                <div className="aboutus___images wrapper" data-aos='zoom-out-down'>
                    {users.map(user => (
                        <Team img={user.img} name={user.user} pro={user.pro} descr={user.descr} key={user.id} />    
                    ))}
                </div>
            </div>
        </div>
    )
}

export default OpusTeam
