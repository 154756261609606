import { createGlobalStyle } from "styled-components";

export const lightTheme = {
    body: "white",
    fontColor: "#2C2E43",
};

export const darkTheme = {
    body: "#333654",
    fontColor: "#F6F7F8",
    primary: "#3d3f5c",
    switch: "#ffe380"
};

export const GlobalStyles = createGlobalStyle`

    .darkmode__lightmode__switch{
        color: ${(props) => props.theme.switch};
    }

    
    .call__center__page,
    .it__services,
    body {
        background-color: ${(props) => props.theme.body};
        transition: all 0.3s ease;
    }

    .is__services__card .card,
    .call__center__card .card,
    .triangle-topleft2, 
    .about__us__description .aboutus__desc__block,
    .why__us,
    .about__us__section,
    .services__cards .card {
        background-color: ${(props) => props.theme.primary};
        transition: all 0.3s ease;
    }

    .it__services .is__services__card .card__tittle,
    .it__services .is__services__card .card__description,
    .call__center__page .call__center__card .card__tittle,
    .call__center__page .call__center__card .card__description,
    .contactus__tittle,
    .about__us__description h3,
    .about__us__description p,
    .why__us__tittle h3,
    .why__us__description,
    .it__services__tittle h3,
    .it__services__description li,
    .call__center__tittle h3,
    .call__center__description li,
    .about__us__section h3,
    .about__us__section p,
    .card__dontent__description {
        color: ${(props) => props.theme.fontColor};
        transition: all 0.5s ease;
    }

    `;