import React from 'react'
import Footer from './footer/Footer'
import ItService from './itservices/ItService'

const ItServices = () => {
    return (
        <div>
            <ItService />
            <Footer />
        </div>
    )
}

export default ItServices

