import React from 'react'
import './HeaderCarousel.css';

// import presantation from '../../assets/Video/presantation.mp4';
import presantation from '../../assets/Video/vid.mp4';

import Typical from 'react-typical';


const HeaderCarousel = () => {

    const videoSrc = presantation;
 
    return(
    <div className="header__carousel">

                        <video className="aboutus__video__play" autoPlay loop muted width="100%" height="auto">
                            <source src={videoSrc} type="video/mp4" />
                        </video>
                            <div className="overlay">
                               
                                <Typical 
                                        loop={Infinity}
                                        wrapper='i'
                                        steps={[
                                            'Happy Employees = Happy Customers',
                                            2000,
                                            '“It’s not what you say but how you say it!” The importance of voice in customer service.',
                                            2000,
                                            'Only a life lived in the service to others is worth living.',
                                            2000
                                        ]} />
                               
                            </div>

    </div>
    )
}

export default HeaderCarousel