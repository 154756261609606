import React from 'react'

import OpusTeam from './testimonials/OpusTeam'
import Footer from './footer/Footer'

const Testimonials = () => {
    return (
        <div>
            <OpusTeam />
            <Footer />
        </div>
    )
}

export default Testimonials
