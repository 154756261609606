import React from 'react'
import './WhyUs.css';

//translantor
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const WhyUs = () => {
    //translantion function
    const { t } = useTranslation()

    return (
        <div className="why__us">
            <div className="why__us__tittle" data-aos='fade-up'>
                <h3>  {t('why_chose_us')} </h3>
            </div>
            <div className="why__us__description container" data-aos='fade-up'>
                <p> <b> {t('company_name')} </b> {t('why_us_descr')} </p>
            </div>
            <div className="container goto__leadership">
                <Link to="/testimonials" className="btn goto__leadership__btn"> {t('why_us_btn')} </Link>
            </div>
        </div>
    )
}

export default WhyUs
