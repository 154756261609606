import React from 'react'

import Content from './About_us/content/Content';
import Footer from './footer/Footer';

const About = () => {
       return (
              <>
                     <Content />
                     <Footer />
              </>
       )
}

export default About
