import React from "react";

import BePartners from './BePartner/BePartner';
import Footer from "./footer/Footer";

const BePartner = () =>{
    return (
        <div>
            <BePartners/>
            <Footer/>
        </div>
    )
}

export default BePartner