import React from 'react'
import Footer from './footer/Footer'

import GalleryImg from './gallery/GalleryImg'

const Gallery = () => {

    
    return (
        <div>
            <GalleryImg />
            <Footer />
        </div>
    )
}

export default Gallery
