import React from 'react'
import { useEffect } from 'react'
import './ItServices.css'

import { Card } from 'react-bootstrap'
import { Row } from 'react-bootstrap'
import { Col } from 'react-bootstrap'

import img1 from './assets/Server-maintance.png'
import img2 from './assets/hosting.png'
import img3 from './assets/web-develoment.png'
import img4 from './assets/app-development.png'

//translantor
import { useTranslation } from 'react-i18next'

const ItServices = () => {
    useEffect(() => window.scrollTo(0, 0), []);
    //translantor function
    const { t } = useTranslation()

    return (
        <div className="it__services">
            {/* Add shape on background */}
            <div className="moon"></div>
            <div className="moon2"></div>
            <div className="moon3"></div>
            <div className="moon4"></div>
            <div className="moon5"></div>
            {/* <div className="moon6"></div> */}
            <div className="moon7"></div>
            <div className="moon8"></div>
            {/* <div className="moon9"></div> */}
            {/* <div className="moon10"></div> */}
            {/* shape on background end here */}
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="is__services__card" data-aos='fade-up'>
                            <Card className='card' >
                                <Row className='no-gutters'>
                                    <Col md={4} lg={4}  >
                                        <Card.Img className='card__img' variant="top" src={img1} />
                                    </Col>
                                    <Col>
                                        <Card.Body>
                                            <Card.Title className='card__tittle' >{t('server_maintanance')}</Card.Title>
                                            <Card.Text className='card__description'>
                                            {t('server_maintanance_card')}
                                            </Card.Text>
                                            <a href="mailto:hr.opus@hotmail.com?subject=🆘 Server Maintanance&body=Hello Opus! I need help with Server Maintanance" target='_blank' rel='noreferrer' className='btn btn-yellow order__btn col-md-4 text-center'> {t('order_card_btn')} <i className="fas fa-envelope"></i>
                                                <div className="button__horizontal"></div>
                                                <div className="button__vertical"></div>
                                            </a> 
                                        </Card.Body>
                                    </Col>
                                </Row>
                            </Card>
                        </div>
                        <div className="is__services__card" data-aos='fade-up'>
                            <Card className='card' >
                                <Row className='no-gutters'>
                                    <Col md={4} lg={4}  >
                                        <Card.Img className='card__img' variant="top" src={img2} />
                                    </Col>
                                    <Col>
                                        <Card.Body>
                                            <Card.Title className='card__tittle' > {t('hosting')} </Card.Title>
                                            <Card.Text className='card__description'>
                                            {t('hosting_card')}
                                            <li>{t('hosting_card_list_1')}</li>
                                            <li>{t('hosting_card_list_2')}</li>
                                            <li>{t('hosting_card_list_3')}</li>
                                            <li>{t('hosting_card_list_4')}</li>
                                            <li>{t('hosting_card_list_5')}</li>
                                            </Card.Text>
                                            <a href="mailto:hr.opus@hotmail.com?subject=🆘 Hosting&body=Hello Opus! I need help with Hosting" target='_blank' rel='noreferrer' className='btn btn-yellow order__btn col-md-4 text-center'> {t('order_card_btn')} <i className="fas fa-envelope"></i>
                                                <div className="button__horizontal"></div>
                                                <div className="button__vertical"></div>
                                            </a> 
                                        </Card.Body>
                                    </Col>
                                </Row>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ItServices
